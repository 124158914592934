import * as moment from "moment";

import { Nullable, ScheduleTypeEnum } from "types";

interface ClientScheduleType {
  dayNumber: string;
  firstPayment: Nullable<Date>;
  firstPaymentDay: string;
  nextPayment: Nullable<Date>;
  secondPaymentDay: string;
  type: "" | ScheduleTypeEnum;
  weekNumber: string;
  final_payment_month?: string;
  final_payment_year?: string;
  paymentEndOn?: Nullable<Date>;
}

function transformApiSchedule(apiSchedule): ClientScheduleType {
  if (!apiSchedule) return { type: "" } as ClientScheduleType;

  const {
    dayNumber,
    firstPayment,
    firstPaymentDay,
    nextPayment,
    secondPaymentDay,
    weekNumber,
    ...schedule
  } = apiSchedule;

  return {
    ...schedule,
    dayNumber: String(dayNumber || ""),
    firstPayment: firstPayment ? moment(firstPayment).toDate() : null,
    firstPaymentDay: String(firstPaymentDay || ""),
    nextPayment: nextPayment ? moment(nextPayment).toDate() : null,
    secondPaymentDay: String(secondPaymentDay || ""),
    weekNumber: String(weekNumber || ""),
  };
}

export { ClientScheduleType, transformApiSchedule as default };
